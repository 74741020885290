@import '../../../custom.scss';

.testimonialWrapper {
    padding: 100px 0px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .testimonialTitleContainer {
        width: 100%;
        text-align: center;

        p {
            color: $primary;
            font-size: 2vw;
            font-weight: bold;

            @media screen and (max-width:700px) {
                font-size: 4vw;
            }
        }
    }

    .testimonialSubTitleContainer {
        text-align: center;

        p {
            color: $dark-green;
            font-size: 3vw;
            font-weight: bold;

            @media screen and (max-width:700px) {
                font-size: 4.5vw;
            }
        }
    }

    .testimonialsContainer {
        padding: 24px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        height: 60vh;
        overflow: auto;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        border-radius: 24px;

        .testimonialContainer {
            width: 400px !important;
            height: 400px;
            padding: 24px;
            border: 1px solid $dark-green;
            border-radius: 24px;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            margin-left: 16px;
            margin-bottom: 24px;

            @media screen and (max-width: 400px) {
                width: 250px !important;
                height: 320px;
                margin-left: 0px;

            }

            .testimonialHeaderContainer {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .testimonialAvatar {
                    img {
                        border-radius: 24px;
                        width: 150px;
                        height: 150px;

                        @media screen and (max-width:400px) {
                            width: 100px;
                            height: 100px;
                        }
                    }
                }

                .ratingNameContainer {

                    .nameContainer {
                        margin: 8px 0px;

                        p {
                            margin: 0;
                            color: $brown;
                            text-align: center;
                            font-weight: bold;
                            font-size: 1.5vw;

                            @media screen and (max-width: 800px) {
                                font-size: 2.5vw;
                            }

                            @media screen and (max-width: 450px) {
                                font-size: 4vw;
                            }
                        }
                    }

                    .ratingContainer {
                        margin: 8px 0px;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }

            .testimonialBodyContainer {
                padding-top: 16px;
                text-align: center;

                p {
                    font-weight: bold;
                    font-size: 1vw;

                    @media screen and (max-width:1050px) {
                        font-size: 1.5vw;
                    }

                    @media screen and (max-width:750px) {
                        font-size: 2.5vw;
                    }

                }

                @media screen and (max-width: 330px) {
                    text-align: center;

                }
            }


        }
    }
}

.testimonialsContainer::-webkit-scrollbar {
    display: none !important;
}