$primary: #DA9C2A;
$dark-green: #005248;
$brown: #4B2000;
$dark-blue: #0600b6;
$secondary: #0068FF;
$white : #fff;
$black: #000;
$very-light-blue: #B6C2FF;
$light-blue: #00A4FF;
$light-gray: #ced4da;
$cyan: #00C6F6;