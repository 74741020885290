@import '../../../custom.scss';

.AccordionWrapper {
    padding: 64px 0px;

    .AccordionTitleContainer {
        text-align: center;

        p {
            color: $primary;
            font-size: 3vw;
            font-weight: bolder;

            @media screen and (max-width:800px) {
                font-size: 5vw;
            }
        }
    }

    .AccoridonContainer {

        .accordion-button {
            font-weight: bold !important;
            color: $primary;
            background-color: $dark-green;
        }
    }
}

.accordion-button::after {
    background-image: url(../../../assets/images/caret-down.png) !important;
}