@import '../../custom.scss';

.signupFieldsWrapper {
    padding: 32px 0px;

    .input {
        border-radius: 100px;
    }

    .errorMessage {
        text-align: center;
        padding: 8px 0px;
        color: green;
        font-weight: bold;
        margin: 8px 0px
    }

    .phoneNumberInput {
        border: 1px solid #ced4da !important;
        padding: 5px 8px;
        border-radius: 100px;

        .PhoneInputInput {
            border: none !important;
        }
    }

    .signUpSubmitButton {
        width: 100% !important;
        background-color: $primary !important;
        border: none !important;
        padding: 16px !important;
        border-radius: 100px !important;
        color: $white !important;
    }
}