@import '../../custom.scss';

.myTicketWrapper {
    // padding: 32px 0px;

    .ticketReceiver {
        text-align: center;
        color: $primary;
    }

    .ticketIdTitle {
        text-align: center;

        span {
            background-color: $dark-green;
            padding: 8px;
            color: $white;
            border-radius: 100px;
            font-size: 18px;
        }
    }

    .input {
        border-radius: 100px;
    }

    .phoneNumberInput {
        border: 1px solid $light-gray !important;
        padding: 5px 8px;
        border-radius: 100px;

        .PhoneInputInput {
            border: none !important;
        }
    }

    .dateLossInput {
        width: 100%;
        border: 1px solid $light-gray !important;
        padding: 6px 8px;
        border-radius: 100px;
    }

    .sendTicketSubmitButton {
        width: 100% !important;
        background-color: $primary !important;
        border: none !important;
        padding: 12px !important;
        border-radius: 100px !important;
        color: $white !important;
    }
}