@import '../../../custom.scss';


.serviceHighlightWrapper {
    padding: 32px 0px;
    height: auto;
    background-color: $black;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media screen and (min-width:1400px) {
        height: 100vh;
        background-image: url(../../../assets/images/blurCryptoBackground3.jpg);
        background-size: 100% 100%;
    }

    .serviceHighlightMainTitle {
        color: $white;
        font-size: 2.3vw;
        font-weight: bold;
        text-align: center;

        @media screen and (max-width:800px) {
            font-size: 4.6vw;
        }
    }

    .serviceHighlightCardsContainer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;

        .serviceHighlightCard {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            background-color: $primary;
            border-radius: 24px;
            width: 400px;
            height: 400px;
            margin: 16px;

            @media screen and (min-width:1400px) {
                background-color: $black;
            }

            .serviceHighlightTitle {
                padding: 16px 0px;
                text-align: center;
                font-size: 2vw;
                font-weight: bold;
                color: $dark-green;

                @media screen and (max-width: 700px) {
                    font-size: 4vw;
                }

                @media screen and (min-width:1400px) {
                    color: $primary;
                }

            }

            .serviceHighlightDescription {
                padding: 16px;
                text-align: center;
                color: $white;

                @media screen and (max-width:350px) {
                    font-size: 14px;
                }
            }
        }
    }
}