@import '../../../custom.scss';

.footerWrapper {
    width: 100%;
    background-color: $black;

    .footerBootstrapContainer {
        @media screen and (max-width:767.5px) {
            margin: 0px 0px;
            max-width: 100% !important;
        }
    }

    .footerContainer {
        width: 100%;
        height: auto !important;
        padding: 24px 0px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-wrap: wrap;
        color: $white;

        @media screen and (max-width:1199.5px) {
            height: auto !important;
        }

        @media screen and (max-width:767.5px) {
            justify-content: space-around;
        }

        .footerLinksContainer {
            @media screen and (max-width:1199.5px) {
                margin-top: 16px;
            }

            .footerLink {
                cursor: pointer;
                color: $white;
                text-decoration: none;

                div {
                    p {
                        margin-bottom: 4px !important;
                    }
                }
            }
        }
    }

    .copyrightContainer {
        color: $white;
        text-align: center;

        p {
            margin: 0 !important;
            padding: 8px 0px;
            font-size: 1vw;

            @media screen and (max-width:700px) {
                font-size: 2vw;
            }

            @media screen and (max-width:400px) {
                font-size: 3vw;
            }
        }

    }
}

.footerTitles {
    font-size: 1.2vw;
    color: $primary;
    font-weight: bold;

    @media screen and (max-width:800px) {
        font-size: 2vw;
    }

    @media screen and (max-width:500px) {
        font-size: 3vw;
    }
}

.footerBody {
    // height: 120px;
    display: flex;
    flex-direction: row;


    @media screen and (max-width:1199.5px) {
        height: auto;
    }

    @media screen and (max-width:375px) {
        font-size: 3.5vw;
    }

    .footerLink {
        padding: 8px;
    }
}