@import '../../custom.scss';

.profileFieldsWrapper {
    padding: 32px 0px;

    .input {
        border-radius: 100px;
    }

    .editSubmitButton {
        width: 100% !important;
        background-color: $primary !important;
        border: none !important;
        padding: 12px !important;
        border-radius: 100px !important;
        color: $white !important;
    }
}