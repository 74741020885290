@import '../../custom.scss';

.signInWrapper {
    padding: 24px 0px;

    .input {
        border-radius: 100px;
    }

    .signInMessage {
        color: green;
        text-align: center;
        padding: 4px 0px;
    }

    .signInSubmitButton {
        width: 100% !important;
        background-color: $primary !important;
        border-radius: 100px !important;
        border: none !important;
        padding: 16px !important;
        color: $white !important;
    }
}