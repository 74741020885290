@import '../../../custom.scss';

.navbar {
    transition: background-color 0.5s;
}

.navbarLogo {
    @media screen and (max-width: 350px) {
        display: none;
    }
}

.navbarMobileLogo {
    display: none;

    @media screen and (max-width: 350px) {
        display: block;
    }
}

.navbar.scrolled {
    background-color: $black;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.navbarLinks {
    text-decoration: none;
    text-transform: uppercase;

    p {
        font-size: 14px;
        font-weight: bold;
        padding: 8px 16px;
        color: $white;
        margin: 0 !important;
    }
}

.navbarLink {
    text-decoration: none;
    color: $black !important;
}

.active {
    text-decoration: none !important;
    text-transform: uppercase;

    p {
        font-size: 14px;
        font-weight: bold;
        padding: 8px 16px;
        color: $primary;
        margin: 0 !important;
    }

}


.offcanvasNavbarContainer {
    background-color: $black;
}

.offCanvasTitle {
    color: $white;
}

.navbar-light .navbar-toggler {
    border: none !important;
}

.navbar-light .navbar-toggler-icon {
    background-image: url(../../../assets/images/menu.png) !important;
}

.offcanvas-header .btn-close {
    background-image: url(../../../assets/images/close.png) !important;
    background-size: 100% 100%;
}

.dropdown-toggle {
    margin-top: 8px;
    background-color: $primary !important;
    border: none !important;
    // border-radius: 100px;
}

.dropdown-toggle::after {
    display: none !important;
}

.navbarSingUpButton {
    margin-top: 8px;
    border-radius: 100px;
    background-color: #DA9C2A;
    border: none;
    margin-left: 8px;

    @media screen and (max-width:991.5px) {
        margin-left: 0px !important;
    }
}

.navbarSingUpButton:hover,
.navbarSingUpButton:focus {
    background-color: #DA9C2A !important;
}