@import '../../../custom.scss';

.recoveryServiceWrapper {
    padding: 100px 0px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: $black;

    .recoveryServiceTitleOne {
        font-weight: bold;
        font-size: 2vw;
        color: $white;

        @media screen and (max-width: 650px) {
            font-size: 3.5vw;
        }
    }

    .recoveryServiceTitleTwo {
        font-weight: bolder;
        font-size: 3.2vw;
        color: $primary;

        @media screen and (max-width: 650px) {
            font-size: 5vw;
        }
    }

    .recoveryServiceDescriptionOne {
        font-size: 1vw;
    }

    .recoverySubTitle {
        padding: 10px 0px;
        color: $primary;
        font-size: 1.5vw;
        font-weight: bold;

        @media screen and (max-width: 650px) {
            font-size: 3.5vw;
        }
    }

    .recoveryServiceButton {
        margin: 16px 0px;
        padding: 12px 20px;
        background-color: $dark-green !important;
        border-radius: 100px !important;
        border: none;
        font-weight: bold;
        font-size: 18px;
    }
}