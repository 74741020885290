@import '../../../custom.scss';

.floatingContainerWrapper {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    width: 100%;
    height: auto;
    padding: 24px 0px;
    border-radius: 24px;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width:575.5px) {
        margin-top: 0px;
    }
}