@import '../../../custom.scss';

.introductionWrapper {
    width: 100%;

    .introductionImageContainer {
        height: 100%;
        padding: 0px 5vw;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .introductionFirstTitle {
            color: $white;
            font-size: 2.3vw;
            font-weight: bold;
            text-align: center;

            @media screen and (max-width:800px) {
                font-size: 4vw;
            }
        }

        .introductionMainTitle {
            color: $white;
            font-size: 4vw;
            font-weight: bolder;
            text-align: center;

            @media screen and (max-width:800px) {
                font-size: 5vw;
            }
        }

        .introductionSecondTitle {
            color: $white;
            font-size: 1.2vw;
            font-weight: 400;
            text-align: center;

            @media screen and (max-width:800px) {
                font-size: 2.2vw;
            }

            @media screen and (max-width:450px) {
                font-size: 3.5vw;
            }
        }
    }

}