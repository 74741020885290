@import '../../../custom.scss';

.introduction2Wrapper {
    width: 100%;

    .introduction2ImageContainer {
        height: 100%;
        padding: 0px 5vw;
        background-repeat: no-repeat;
        background-size: 100% 100%;


        .introduction2TitlesContainer {
            height: 100vh;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;

            .introduction2FirstTitle {
                color: $white;
                font-size: 2.3vw;
                font-weight: bold;
                text-align: left;

                @media screen and (max-width:800px) {
                    font-size: 4vw;
                }

                @media screen and (max-width:500px) {
                    font-size: 8vw;
                }
            }

            .introduction2MainTitle {
                color: $white;
                font-size: 4vw;
                font-weight: bolder;
                text-align: left;

                @media screen and (max-width:800px) {
                    font-size: 5vw;
                }

                @media screen and (max-width:500px) {
                    font-size: 8vw;
                }
            }

            .introduction2SecondTitle {
                color: $white;
                font-size: 1.2vw;
                font-weight: 400;
                text-align: left;

                @media screen and (max-width:800px) {
                    font-size: 2.2vw;
                }

                @media screen and (max-width:500px) {
                    font-size: 3.5vw;
                }
            }
        }

        .introduction2ChildrenContainer {
            height: 100vh;
            display: flex;
            align-items: center;
            justify-content: center;

            @media screen and (max-width:575.5px) {
                height: auto;
            }
        }
    }

}