@import '../../custom.scss';

.ticketProgressWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .ticketProgressContainer {
        height: 400px;
        padding: 0px 8px;
        width: 100%;
        overflow-y: auto;
        overflow-x: auto;

        .ticketProgressTable {
            width: 100%;
            margin: 0;
            position: relative;
            border-collapse: collapse;

            .tableHeader {
                padding: 16px;
                position: sticky;
                top: -16px;
                background-color: $primary;
                color: $white;
                text-align: center;
            }

            .tableHeader:nth-child(1) {
                border-radius: 10px 0px 0px 10px;
            }

            .tableHeader:nth-child(3) {
                border-radius: 0px 10px 10px 0px;
            }
        }
    }
}