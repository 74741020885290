@import '../../../custom.scss';

.serviceWrapper {

    .serviceTitle {
        padding: 64px 0px 32px 0px;
        text-align: center;

        p {
            font-size: 2.5vw;
            font-weight: bold;
            color: $primary;

            @media screen and (max-width: 600px) {
                font-size: 4vw;
            }
        }
    }
}

.serviceCardContainer {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0px 0px 64px 0px;
}

.card {
    width: 280px;
    height: 350px;
    margin: 8px;
}

.card .image {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: $dark-green;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.card .image div {
    width: 100%;
    transition: .5s;
}

.card:hover .image div {
    opacity: .5;
    transform: translateX(35%);
}

.card .details {
    position: absolute;
    top: 0;
    left: 0;
    width: 72%;
    height: 100%;
    background: $primary;
    transition: .5s;
    transform-origin: left;
    transform: perspective(2000px) rotateY(-90deg);
}

.card:hover .details {
    transform: perspective(2000px) rotateY(0deg);
}

.card .details .center {
    padding: 20px;
    text-align: center;
    background: $white;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.card .details .center h1 {
    margin: 0;
    padding: 0;
    color: $primary;
    font-weight: bold;
    line-height: 20px;
    font-size: 20px;
    text-transform: uppercase;
}

.card .details .center h1 span {
    font-size: 14px;
    color: $black;
}

.card .details .center p {
    margin: 10px 0;
    padding: 0;
    color: $black;
}